import { loadStripe } from '@stripe/stripe-js'
import {
    Elements,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'
import { useFeddiApi } from './contexts/feddiApiContext'
import { useAuth } from './contexts/authContext'
import { useQueryParams } from './contexts/queryParamsContext'
import Layout from './Layout'
import TopupForm from './TopupForm'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '')

export default function Topup() {
    const api = useFeddiApi()
    const { userInfo } = useAuth()
    const { params } = useQueryParams()
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)
    const [paymentIntent, setPaymentIntent] = useState<{
        clientSecret: string
        customer: string
        paymentIntent: string
        publishableKey: string
    } | null>(null)
    const [amount, setAmount] = useState(100)

    const debouncedRequest = useCallback(
        debounce((walletId: string, amount: number) => {
            api
                .requestTopUp({
                    amount: amount,
                    walletId: walletId
                })
                .then(res => setPaymentIntent(res.data.data.paymentIntent))
        }, 500),
        []
    )

    useEffect(() => {
        debouncedRequest(selectedWallet?.id ?? '', amount)
    }, [amount])

    return (
        <Layout>
            {
                !paymentIntent && (
                    <div className="p-6 flex flex-col items-center justify-center">
                        <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
                        <p className="mt-4 text-gray-600">Preparing payment form...</p>
                    </div>
                )
            }
            {
                paymentIntent && (
                    <>
                        <div className="p-4">
                            <label htmlFor="amount" className="block text-sm font-medium text-gray-700 mb-2">
                                Amount to Top Up
                            </label>
                            <div className="relative">
                                <input
                                    type="number"
                                    id="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(Number(e.target.value))}
                                    className="w-full px-3 py-2 pr-12 border border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
                                    min="10"
                                    step="10"
                                />
                                <span className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-500">AED</span>
                            </div>
                        </div>
                        <Elements
                            stripe={stripePromise}
                            options={{
                                clientSecret: paymentIntent?.clientSecret,
                            }}
                        >
                            <TopupForm />
                        </Elements>
                    </>
                )
            }
        </Layout>
    )
}