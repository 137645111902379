import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthProvider } from './contexts/authContext'
import { QueryParamsProvider } from './contexts/queryParamsContext'
import { FeddiApiProvider } from './contexts/feddiApiContext'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryParamsProvider>
        <AuthProvider>
          <FeddiApiProvider>
            <App />
          </FeddiApiProvider>
        </AuthProvider>
      </QueryParamsProvider>
    </HelmetProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
