
export const isIOS = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export const isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    return /android/.test(userAgent)
}
