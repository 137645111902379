import { useState } from 'react'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'

export default function TopupForm() {
    const [error, setError] = useState<string | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!stripe || !elements) {
            return
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: process.env.REACT_APP_WALLET_URL ?? '',
            },
        })


        if (error) {
            setError(error.message ?? 'Payment failed')
            setIsLoading(false)
        }

        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
    }

    return (
        <form className="p-4" onSubmit={handleSubmit}>
            <PaymentElement />
            <button
                className="bg-blue-600 text-white px-4 py-2 rounded-md mt-10 w-full disabled:opacity-50"
                type="submit"
                disabled={!stripe || !elements || isLoading}
            >
                {isLoading ? (
                    <div className="flex items-center justify-center">
                        <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></div>
                        Processing...
                    </div>
                ) : (
                    'Pay Now'
                )}
            </button>
            {error && <div className="p-4 text-red-500">{error}</div>}
        </form>
    )
}