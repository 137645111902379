import React, { createContext, useContext, useState, useCallback, useEffect } from 'react'
import { User, Wallet } from '@feddi/sdk-core/dist/db_types'

export type UserInfo = User & {
  wallets?: Wallet[]
}

interface AuthContextType {
  isAuthenticated: boolean
  userInfo: UserInfo | null
  login: (email: string, password: string) => Promise<void>
  register: (email: string, password: string) => Promise<void>
  logout: () => void
  isLoading: boolean
  error: string | null
}

const AuthContext = createContext<AuthContextType | null>(null)

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const loadUserInfo = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/me?include[wallets]=true`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      })
      const data = await response.json()
      return data.data.user
    } catch (err) {
      throw err
    }
  }, [])

  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token')
      if (token) {
        setIsLoading(true)
        try {
          const userInfo = await loadUserInfo()
          setUserInfo(userInfo)
          setIsAuthenticated(true)
        } catch (err) {
          // If loading fails, clear the invalid token
          // localStorage.removeItem('token')
          setError(err instanceof Error ? err.message : 'Authentication failed')
        } finally {
          setIsLoading(false)
        }
      }
    }

    initializeAuth()
  }, [loadUserInfo])

  const login = useCallback(async (email: string, password: string) => {
    setIsLoading(true)
    setError(null)
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Login failed')
      }

      localStorage.setItem('token', data.data.token)
      const userInfo = await loadUserInfo()
      
      setUserInfo(userInfo)
      setIsAuthenticated(true)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Login failed')
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [loadUserInfo])

  const register = useCallback(async (email: string, password: string) => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      })

      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || 'Registration failed')
      }

      localStorage.setItem('token', data.data.token)
      const userInfo = await loadUserInfo()

      setUserInfo(userInfo)
      setIsAuthenticated(true)
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Registration failed')
      throw err
    } finally {
      setIsLoading(false)
    }
  }, [loadUserInfo])

  const logout = useCallback(() => {
    localStorage.removeItem('token')
    setUserInfo(null)
    setIsAuthenticated(false)
  }, [])

  return (
    <AuthContext.Provider 
      value={{ 
        isAuthenticated, 
        userInfo, 
        login, 
        register, 
        logout,
        isLoading, 
        error 
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth() {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
