import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import './App.css'
import Wallet from './Wallet'
import Auth from './Auth'
import { useAuth } from './contexts/authContext'

function App() {
  const { isAuthenticated, userInfo } = useAuth()

  const renderContent = () => {
    if (!isAuthenticated || !userInfo) {
      return (
        <Auth />
      )
    }

    return <Wallet />
  }

  return (
    <>
      <Helmet>
        <title>Feddi Wallet</title>
        <meta name="description" content="Feddi integrated wallet" />
        <meta name="author" content="Feddi" />
        <meta name="keywords" content="Feddi, wallet, payment, crypto" />
        <meta name="mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
      </Helmet>
      {renderContent()}
    </>
  )
}

export default App
