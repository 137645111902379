import { createContext, useContext } from 'react'
import { FeddiApi } from '@feddi/sdk-core'

const api = new FeddiApi(process.env.REACT_APP_API_URL?.toString() ?? '')
api.attachAuthToken(localStorage.getItem('token') ?? '')

const FeddiApiContext = createContext<FeddiApi>(api)

export function useFeddiApi() {
    return useContext(FeddiApiContext)
}

export function FeddiApiProvider({ children }: { children: React.ReactNode }) {
    return (
        <FeddiApiContext.Provider value={api}>
            {children}
        </FeddiApiContext.Provider>
    )
}
