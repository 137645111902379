import { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash/debounce'
import { useAuth } from './contexts/authContext'
import { useQueryParams } from './contexts/queryParamsContext'
import { FiCreditCard, FiSettings, FiLogOut } from 'react-icons/fi'
import { isAndroid, isIOS } from './utils'
import Topup from './Topup'
import { useFeddiApi } from './contexts/feddiApiContext'
import Layout from './Layout'

interface WalletProps { }

function Wallet(props: WalletProps) {
    const { userInfo, logout } = useAuth()
    const api = useFeddiApi()
    const { params } = useQueryParams()
    const [qrCode, setQrCode] = useState<string | null>(null)
    const [showTopup, setShowTopup] = useState(false)
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)

    const debouncedRequest = useCallback(
        debounce((walletId: string) => {
            api
                .getWalletQRCode(walletId)
                .then(res => setQrCode(res.data.data.qrCode))
        }, 250),
        [api]
    )

    const debounceConfirmTopup = useCallback(
        debounce((paymentIntentId: string) => {
            api
                .confirmTopUp({ paymentIntentId })
                .then(() => {
                    alert('Topup confirmed')
                    window.history.pushState({}, document.title, window.location.pathname)
                })
                .catch((error) => {
                    alert(`Error confirming topup: code ${error.response?.data.error.code}`)
                })
        }, 250),
        [api]
    )

    useEffect(() => {
        if (userInfo && selectedWallet) {
            debouncedRequest(selectedWallet.id!)
        }
    }, [selectedWallet])

    useEffect(() => {
        if (params.payment_intent && params.redirect_status === 'succeeded') {
            debounceConfirmTopup(params.payment_intent)
        }
    }, [params])

    const toggleTopup = () => {
        setShowTopup(!showTopup)
    }

    if (showTopup) {
        return <Topup />
    }

    return (
        <Layout>
            {/* QR Code Container */}
            <div className="p-6 flex justify-center">
                <div className="bg-gray-100 p-4 rounded-lg">
                    {/* Replace with actual QR code component */}
                    <div className="w-48 h-48 bg-gray-200 flex items-center justify-center text-gray-400">
                        {qrCode ? <img src={qrCode} alt="QR Code" /> : 'QR Code'}
                    </div>
                    <div className="text-center mt-2 text-sm text-gray-600">
                        Scan to make payment
                    </div>
                </div>
            </div>

            {/* Action Buttons */}
            <div className="px-6 pb-6 grid grid-cols-2 gap-4">
                <button
                    className="bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center"
                    onClick={toggleTopup}
                >
                    <FiCreditCard className='mr-2 text-2xl' />
                    <span className='text-xl font-light'>Top up</span>
                </button>
                <button className="bg-green-600 text-white py-3 px-4 rounded-lg hover:bg-green-700 transition-colors flex items-center justify-center">
                    <FiSettings className='mr-2 text-2xl' />
                    <span className='text-xl font-light'>Settings</span>
                </button>
            </div>

            <div className="px-6 pb-6">
                {
                    isAndroid() && (
                        <div>
                            <img
                                src="/add-to-google-pay/en/Save to Google Pay - English (Dark).svg"
                                alt="Add to Google Wallet"
                                className="w-full"
                            />
                        </div>
                    )
                }
                {
                    isIOS() && (
                        <div className="flex justify-center">
                            <img
                                src="/add-to-apple-wallet/US_UK/RGB/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
                                alt="Add to Apple Wallet"
                                className="w-3/5"
                                onClick={async () => {
                                    const response = await fetch(
                                        process.env.REACT_APP_API_URL + '/passkit/download-pass?walletId=' + selectedWallet?.id + '&type=apple',
                                        {
                                            headers: {
                                                'Authorization': `Bearer ${localStorage.getItem('token')}`
                                            }
                                        }
                                    )
                                    const file = await response.blob()
                                    const blob = new Blob([file], { type: 'application/vnd.apple.pkpass' })
                                    const url = window.URL.createObjectURL(blob)
                                    const a = document.createElement('a')
                                    a.href = url
                                    a.download = "pass.pkpass"
                                    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
                                    a.click()
                                    a.remove()  //afterwards we remove the element again
                                }}
                            />
                        </div>
                    )
                }
            </div>

            {/* Recent Transactions */}
            <div className="border-t border-gray-200 px-6 py-4">
                <h2 className="text-lg font-semibold mb-4">Recent Transactions</h2>
                <div className="space-y-3">
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="font-medium">Spent</div>
                            <div className="text-sm text-gray-500">Sport Society</div>
                        </div>
                        <div className="text-red-600">-200</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="font-medium">Spent</div>
                            <div className="text-sm text-gray-500">Paratha King</div>
                        </div>
                        <div className="text-red-600">-34</div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Wallet
