import { FiLogOut } from 'react-icons/fi'
import { useAuth } from './contexts/authContext'
import { useQueryParams } from './contexts/queryParamsContext'

export default function Layout({ children }: { children: React.ReactNode }) {
    const { userInfo, logout } = useAuth()
    const { params } = useQueryParams()
    const selectedWallet = userInfo?.wallets?.find(wallet => wallet.communityId === params.communityId)

    return (
        <div className="min-h-screen bg-gray-100 p-4">
            <div className="max-w-md mx-auto bg-white rounded-xl shadow-lg overflow-hidden">
                {/* Header */}
                <div className="bg-blue-600 p-6 text-white">
                    <div className="flex justify-between items-start">
                        <h1 className="text-2xl font-bold">
                            {
                                selectedWallet?.name || 'My Wallet'
                            }
                        </h1>
                        <button
                            className="bg-white text-white py-2 px-2 mx-2 rounded-full"
                            onClick={logout}
                        >
                            <FiLogOut className='text-blue-600' />
                        </button>
                    </div>
                    <div className="mt-4 text-3xl font-bold">
                        {
                            selectedWallet?.token
                        }
                        &nbsp;
                        {
                            selectedWallet?.balance
                        }
                    </div>
                    <div className="text-blue-100">Available Balance</div>
                </div>

                {children}
            </div>
        </div>
    )
}